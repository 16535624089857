import React from "react"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

const styles = {
  root: {
    width: "100%",
    height: { base: "100%", md: "100%", lg: "350px" },
    px: "24px",
    bg: "#F7F7F7",
  },
  "inner-box": {
    margin: "0 auto",
    py: { base: "36px", md: "36px", lg: "0px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  "text-box": {
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "420px" },
    alignItems: "center",
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    color: "rgb(18, 148, 88)",
    fontWeight: "bold",
    textAlign: "center",
  },
  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: "center",
  },
}

const StaticPageD = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["text-box"]}>
          <Heading sx={styles.heading}>
            Healthcare Data Analytics Done Right
          </Heading>
          <Text sx={styles.text}>
            Avant-garde Health was born out of the Harvard Business School’s
            value-based health care delivery research, led by Professors Michael
            Porter and Robert Kaplan. Our growing team is backed by a group of
            leading investors, and we are dedicated to transforming health care
            delivery for years to come.
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default StaticPageD
