import React from "react"
import StaticPageA from "./static-page-a"
import StaticPageB from "./static-page-b"
import StaticPageC from "./static-page-c"
import StaticPageD from "./static-page-d"

const StaticPages = () => (
  <>
    <StaticPageA />
    <StaticPageB />
    <StaticPageC />
    <StaticPageD />
  </>
)

export default StaticPages
