import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

const styles = {
  root: {
    width: "100%",
    height: { base: "100%", md: "100%", lg: "600px" },
    px: "24px",
  },
  "inner-box": {
    margin: "0 auto",
    py: { base: "36px", md: "36px", lg: "0px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  "text-box": {
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "410px" },
    alignItems: "center",
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    fontWeight: "bold",
    textAlign: "center",
  },
  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: "center",
  },
  image: {
    marginTop: "40px",
    height: { base: "auto", md: "auto", lg: "200px" },
    maxWidth: { base: "100%", md: "600px", lg: "723px" },
  },
}

const StaticPageC = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["text-box"]}>
          <Heading sx={styles.heading}>
            Understand your operations, improve your service, cut your costs
          </Heading>
          <Text sx={styles.text}>
            Your success is our #1 priority and we have a strong Customer
            Success Team that will help you create the playbook to execute on
            the opportunities and drive real tangible results.
          </Text>
        </Flex>
        <Box sx={styles.image}>
          <StaticImage
            src="../../images/splash/splash-hero-c.png"
            alt="Image of doctor showing charts"
            placeholder="blurred"
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default StaticPageC
