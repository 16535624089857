import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

const styles = {
  root: {
    width: "100%",
    height: { base: "100%", md: "100%", lg: "450px" },
    px: "24px",
  },
  "inner-box": {
    margin: "0 auto",
    mt: { base: "36px", md: "36px", lg: "0px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "center",
    maxWidth: { base: "100%", md: "100%", lg: "1040px" },
    height: "100%",
    justifyContent: {
      base: "flex-start",
      md: "flex-start",
      lg: "space-between",
    },
  },
  "text-box": {
    flexDirection: "column",
    width: { base: "100%", md: "100%", lg: "460px" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    fontWeight: "bold",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  image: {
    height: { base: "auto", md: "auto", lg: "362px" },
    maxWidth: { base: "100%", md: "100%", lg: "410px" },
  },
}

const StaticPageA = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["text-box"]}>
          <Heading sx={styles.heading}>
            Predictive healthcare analytics to reduce costs and optimize patient
            care
          </Heading>
          <Text sx={styles.text}>
            Avant-garde Health transforms data & insights into actions, and we
            help our clients achieve real cost savings by utilizing
            Time-Driven-Activity-Based Costing (TDABC) to identify key drivers
            for cost-savings.
          </Text>
        </Flex>
        <Box sx={styles.image}>
          <StaticImage
            src="../../images/splash/splash-hero-a.png"
            alt="Image of doctor showing charts"
            placeholder="blurred"
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default StaticPageA
