import React from "react"
import rehypeReact from "rehype-react"
import { Flex, Box, Image, useToast } from "@chakra-ui/react"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import isEmail from "validator/lib/isEmail"
import isEmpty from "validator/es/lib/isEmpty"
import get from "lodash/get"
import Cookie from "js-cookie"
import { postForm } from "../api"
import SplashForm from "../components/splash/form"
import SplashHeading from "../components/splash/heading"
import Layout from "../components/layout"
import MessageConfirmation from "../components/contact-us/message-confirmation"
import StaticPages from "../components/splash/static-pages"
import Video from "../components/video"
import BlogAnchor from "../components/blog-anchor"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { a: BlogAnchor, video: Video },
}).Compiler

const styles = {
  root: {
    width: "100%",
    px: "24px",
    bg: "#F9F9F9",
    minHeight: "calc(100vh - 96px)",
  },
  "row-box": {
    margin: "0 auto",
    pt: "32px",
    pb: "64px",
    maxWidth: { base: "100%", md: "100%", lg: "1440px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "center", md: "center", lg: "space-between" },
  },
  "column-box": {
    margin: "0 auto",
    pt: "32px",
    pb: "64px",
    maxWidth: { base: "100%", md: "100%", lg: "1440px" },
    flexDirection: "column",
    alignItems: "center",
  },
  "form-box-row": {
    mt: { base: "48px", md: "48px", lg: "0px" },
    ml: { base: "0px", md: "0px", lg: "48px" },
    width: { base: "100%", sm: "432px", md: "432px", lg: "432px" },
  },
  "form-box-column": {
    mt: "48px",
    width: { base: "100%", sm: "432px", md: "432px", lg: "432px" },
  },
  image: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    height: { base: "auto", md: "auto", lg: "313.95px" },
    maxWidth: { base: "100%", md: "100%", lg: "658px" },
  },
}

const createValidator = field => value => {
  if (field.required && isEmpty(value)) {
    return `${field.label} is required`
  }
  if (field.name === "email" && !isEmail(value)) {
    return "Please enter a valid email address"
  }
  return ""
}

const createField = fg => {
  const fields = fg.fields.map(field => ({
    ...field,
    id: field.name,
    validate: createValidator(field),
    value: "",
    hint: "",
  }))
  return fields.length > 1 ? fields : fields[0]
}

const SplashPageTemplate = props => {
  const toast = useToast()
  const submitText = get(props, "data.hubspotForm.submitText")
  const fieldGroups = get(props, "data.hubspotForm.formFieldGroups")
  const portalId = get(props, "data.hubspotForm.portalId")
  const heading = get(props, "pageContext.title")
  const image = get(props, "pageContext.image.gatsbyImageData")
  const alt = get(props, "pageContext.image.description")
  const includeCompanyOverview = get(
    props,
    "pageContext.includeCompanyOverview"
  )
  const text = get(
    props,
    "pageContext.explanatoryText.childMarkdownRemark.htmlAst"
  )
  const formId = get(props, "pageContext.formId")
  const pageUri = get(props, "location.href")
  const inlineMessage = get(props, "data.hubspotForm.inlineMessage")
  const hutk = Cookie.get("hubspotutk")
  const [loading, setLoading] = React.useState(false)
  const fields = React.useMemo(
    () => fieldGroups?.map(fg => createField(fg)),
    [fieldGroups]
  )
  const _sxInnerBox = React.useMemo(
    () =>
      (!text && !image) || !formId ? styles["column-box"] : styles["row-box"],
    [text, image, formId]
  )

  const _sxFormBox = React.useMemo(
    () =>
      !text && !image ? styles["form-box-column"] : styles["form-box-row"],
    [text, image]
  )

  const center = React.useMemo(() => {
    if (!formId) return true
    if (!text && !image && formId) return true
    return false
  }, [formId, image, text])

  const handleSubmit = React.useCallback(
    data => {
      if (formId) {
        setLoading(true)
        const context = { hutk, pageUri, pageName: heading }
        postForm({ portalId, formId, data, context }).then(() => {
          setLoading(false)
          toast({
            duration: null,
            render: () => (
              <MessageConfirmation
                message={inlineMessage}
                onClose={() => {
                  toast.closeAll()
                  navigate("/")
                }}
              />
            ),
          })
        })
      }
    },
    [portalId, formId, pageUri, heading, inlineMessage, toast, hutk]
  )

  return (
    <Layout location={props.location} title={heading} hideNavigation>
      <Box sx={styles.root}>
        <Flex sx={_sxInnerBox}>
          <SplashHeading
            heading={heading}
            center={center}
            Image={
              image &&
              (() => (
                <Image
                  as={GatsbyImage}
                  alt={alt ? alt : "Splash page hero image"}
                  image={image}
                  sx={styles.image}
                />
              ))
            }
            text={renderAst(text)}
          />
          {formId && (
            <Box sx={_sxFormBox}>
              <SplashForm
                fields={fields}
                submitText={submitText}
                onSubmit={handleSubmit}
                loading={loading}
              />
            </Box>
          )}
        </Flex>
      </Box>
      {includeCompanyOverview && <StaticPages />}
    </Layout>
  )
}

export default SplashPageTemplate

export const pageQuery = graphql`
  query HubSpotFormById($formId: String) {
    hubspotForm(id: { eq: $formId }) {
      portalId
      name
      submitText
      inlineMessage
      formFieldGroups {
        fields {
          label
          name
          required
          fieldType
        }
      }
    }
  }
`
