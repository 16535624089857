import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

const styles = {
  root: {
    width: "100%",
    height: { base: "100%", md: "100%", lg: "450px" },
    px: "24px",
    bg: "#F9F9F9",
  },
  "inner-box": {
    margin: "0 auto",
    py: { base: "36px", md: "36px", lg: "0px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "center",
    maxWidth: { base: "100%", md: "100%", lg: "1040px" },
    height: "100%",
    justifyContent: {
      base: "flex-start",
      md: "flex-start",
      lg: "space-between",
    },
  },
  "text-box": {
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "460px" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    mt: { base: "24px", md: "24px", lg: "0px" },
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    fontWeight: "bold",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  image: {
    height: { base: "auto", md: "auto", lg: "265px" },
    maxWidth: { base: "100%", md: "100%", lg: "471px" },
  },
}

const StaticPageB = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Box sx={styles.image}>
          <StaticImage
            src="../../images/splash/splash-hero-b.png"
            alt="Image of doctor showing charts"
            placeholder="blurred"
          />
        </Box>
        <Flex sx={styles["text-box"]}>
          <Heading sx={styles.heading}>
            Capture Clinical Insights to reduce 90-day readmission rates by 15%
          </Heading>
          <Text sx={styles.text}>
            Dive deeper into outcomes such as length of stay, readmissions,
            complications, and patient reported key metrics to improve clinical
            practices.
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default StaticPageB
