import React from "react"
import PropTypes from "prop-types"
import { Heading, Flex } from "@chakra-ui/react"
import { useResizeDetector } from "react-resize-detector"

const styles = {
  root: {
    maxWidth: { base: "100%", md: "100%", lg: "700px" },
    flexDirection: "column",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  "root-center": {
    maxWidth: { base: "100%", md: "100%", lg: "700px" },
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  "heading-center": {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
  },
  "text-box": {
    flexDirection: "column",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    textAlign: { base: "center", md: "center", lg: "left" },
    p: {
      mt: { base: "16px", md: "16px", lg: "24px" },
      fontSize: "16px",
      lineHeight: "150%",
      letterSpacing: "0.0015em",
      color: "#041424",
      textAlign: { base: "center", md: "center", lg: "left" },
    },
    "ol, ul": {
      marginTop: "20px",
      paddingLeft: "20px",
      li: {
        textAlign: "left",
      },
    },

    a: {
      color: "#3399FF",
      textDecoration: "underline",
    },
    h1: {
      fontSize: "40px",
      lineHeight: "48px",
      fontWeight: "bold",
      marginTop: "20px",
      textAlign: { base: "center", md: "center", lg: "left" },
    },
    h2: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: "bold",
      marginTop: "20px",
      textAlign: { base: "center", md: "center", lg: "left" },
    },
    h3: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: "bold",
      marginTop: "20px",
      textAlign: { base: "center", md: "center", lg: "left" },
    },
  },
  "text-box-center": {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    p: {
      mt: { base: "16px", md: "16px", lg: "24px" },
      fontSize: "16px",
      lineHeight: "150%",
      letterSpacing: "0.0015em",
      color: "#041424",
      textAlign: "center",
    },
    "ol, ul": {
      marginTop: "20px",
      paddingLeft: "20px",
      li: {
        textAlign: "left",
      },
    },
    a: {
      color: "#3399FF",
      textDecoration: "underline",
    },
    h1: {
      fontSize: "40px",
      lineHeight: "48px",
      fontWeight: "bold",
      marginTop: "20px",
      textAlign: "center",
    },
    h2: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: "bold",
      marginTop: "20px",
      textAlign: "center",
    },
    h3: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: "bold",
      marginTop: "20px",
      textAlign: "center",
    },
  },
}

const SplashHeading = ({ heading, text, Image, center }) => {
  const ref = React.useRef()
  const { width } = useResizeDetector({ targetRef: ref })

  return (
    <Flex ref={ref} sx={center ? styles["root-center"] : styles.root}>
      <Heading sx={center ? styles["heading-center"] : styles.heading}>
        {heading}
      </Heading>
      <Flex
        width={width}
        sx={{
          flexDirection: {
            base: "column-reverse",
            md: "column-reverse",
            lg: "column",
          },
        }}
      >
        <Flex sx={center ? styles["text-box-center"] : styles["text-box"]}>
          {text?.props?.children}
        </Flex>
        {Image && <Image />}
      </Flex>
    </Flex>
  )
}

SplashHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.object,
  Image: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  center: PropTypes.bool,
}

export default SplashHeading
